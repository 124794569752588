@defer (when !conceptService.loading() && !conceptService.loadingErrorMessage()) {
  <div class="h100">
    <awc-grid
      [config]="config">
      <!-- MOVE TO OWN WIDGET COMPONENT -->
      <awc-badge
        [text]="resultsBadgeText"
        [backgroundColor]="backgroundColor"
        [textColor]="textColor"
        [position]="position">
        <div style="width: 320px;" class="col-outer">
          <section-card
            *ngIf="selectedSection === ConceptSections.REQUIREMENT; else altSection"
            [sections]="subsections"
            (selectedSectionIdChange)="redirectHandle($event)"
            [selectedSectionId]="selectedSubSection">
            <div class="h100 config-cont" [slot]="ConceptSubSection.MAIN">
              <app-treeview *ngIf="selectedSection === ConceptSections.REQUIREMENT  && selectedSubSection === ConceptSubSection.MAIN" type="requirement"></app-treeview>
            </div>
            <div class="h100 config-cont" [slot]="ConceptSubSection.RESULTS">
              <results-list *ngIf="selectedSection === ConceptSections.REQUIREMENT && selectedSubSection === ConceptSubSection.RESULTS"></results-list>
              <div *ngIf="cliVersion" class="cli-version">
                Solver version: {{ cliVersion }}
              </div>
            </div>
          </section-card>
          <ng-container *ngIf="selectedSection === ConceptSections.REQUIREMENT">
            <div class="solver-button">
              <awc-button
                [text]="solveRequirementText.value"
                [type]="type"
                [size]="size"
                [disabled]="solveRequirementText.loading"
                [suffixIcon]="{icon: solveRequirementText.icon}"
                (clicked)="solveButtonClicked($event)"></awc-button>
            </div>
          </ng-container>
        </div>
      </awc-badge>
      <ng-template #altSection>
        <awf-dashboard-panel [padding]="Size._0x">
          <div class="panel-header">
            <ng-container [ngSwitch]="selectedSection">
              <ng-container *ngSwitchCase="ConceptSections.CONFIGURATIONS">
                <awc-icon  src="assets/icons/vehicle.svg" [size]="headerIconSize"></awc-icon>
                <span>Vehicle Configurations</span>
              </ng-container>
              <ng-container *ngSwitchCase="ConceptSections.COMPONENT">
                <awc-icon  [icon]="Icons.MODELER" [size]="headerIconSize"></awc-icon>
                <span>Vehicle Components</span>
              </ng-container>
              <ng-container *ngSwitchCase="ConceptSections.ARCHITECTURE">
                <awc-icon  [icon]="Icons.FORCE_ANGLE" [size]="headerIconSize"></awc-icon>
                <span>Vehicle Architecture</span>
              </ng-container>
            </ng-container>
          </div>

          <div class="h100 config-cont">
            <ng-container [ngSwitch]="selectedSection">
              <app-treeview *ngSwitchCase="ConceptSections.CONFIGURATIONS" type="configurations"></app-treeview>
              <app-treeview *ngSwitchCase="ConceptSections.COMPONENT" type="component"></app-treeview>
              <ng-container *ngSwitchCase="ConceptSections.ARCHITECTURE">
                <app-architecture-configurations></app-architecture-configurations>
              </ng-container>
            </ng-container>
          </div>
        </awf-dashboard-panel>
      </ng-template>
      <!-- END MOVE TO OWN COMPONENT -->
      <!-- MAIN PANEL CONTENT -->
      <data-display-panel [type]="selectedSection" [template]="architectureService.template"></data-display-panel>
    </awc-grid>
  </div>
} @placeholder () {
  <div class="h100 flex-cont">
    <div class="inner-cont">
      <awc-logo [appName]="appName"></awc-logo>

      @if (conceptService.loading()) {
        <span>Loading Concept...</span>
        <awc-icon [icon]="Icons.SPINNER"></awc-icon>
      } @else if (conceptService.loadingErrorMessage()) {
        <span>Oops! Something went wrong. If the issue persists, please contact support.</span>
        <div class="error-message-cont">
          <awc-icon [icon]="Icons.LEFT_ARROW"></awc-icon>
          <div><span>{{ conceptService.loadingErrorMessage() }}</span></div>
          <awc-icon [icon]="Icons.RIGHT_ARROW"></awc-icon>
        </div>
      }
    </div>
  </div>
}
