/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {
  SectionSelectAction,
  ActiveTabState,
  ActiveSectionState,
} from '@ansys/andromeda/workspace';
import { SizeValue, Unit } from '@ansys/awc-angular/design';
import { Grid, GridFillMode } from '@ansys/awc-angular/layouts';
import { requirementSections } from '../../../shared/models/sections.model';
import { Icons, IconSize, IconType } from '@ansys/awc-angular/icons';
import {
  ActionContributionsService,
  WorkspaceTabSection,
} from '@ansys/andromeda/contributions';
import {
  ConceptSections,
  ConceptSubSection,
} from 'src/app/shared/enums/concept.enum';
import { InputTypes } from '../../widgets/forms/input/input.component';
import { State } from '@ansys/andromeda/store';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { RequirementsService } from 'src/app/shared/services/requirements.service';
import {
  ColorBackground,
  ColorForeground,
  Size,
} from '@ansys/awc-angular/core';
import { BadgePosition } from '@ansys/awc-angular/chips';
import {
  AWFLocation,
  AWF_LOCATION,
  DialogService,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { StartSolveComponent } from '../../dialogs/start-solve/start-solve.component';
import { Subject, takeUntil } from 'rxjs';
import { SectionCardTab } from '../section-card/types/section-card-tab.type';
import { ArchitectureService } from 'src/app/shared/services/architecture.service';
import { UpdateJobStatus } from '../../../actions';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ConceptService } from 'src/app/shared/services/concept.service';
import { TITLE } from '@ansys/andromeda/auth';
import { JobLoadingStatus } from '../../../shared/enums/job-loading-status.enum';
import {
  AnalyticsEventPayload,
  SubmitAnalyticsEventAction,
} from '@ansys/andromeda/analytics';
import { AnalyticEventEnum } from '../../../shared/enums';

const gridGap = {
  value: 1,
  unit: Unit.REM,
};
interface ButtonStatus {
  value: string;
  loading: boolean;
  icon: Icons;
}

@Component({
  selector: 'app-concept-layout',
  templateUrl: './concept-layout.component.html',
  styleUrls: ['./concept-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptLayoutComponent implements OnDestroy {
  readonly Size = Size;
  // MOVE TO OWN WIDGET
  public vehicleOverviewGradient: number = 0;
  public vehicleOverviewSpeed: number = 50;
  public isEditing: boolean = true;
  public gradientPlaceholder: string = '0';
  public speedPlaceholder: string = '50';

  // SELECT CHECK
  public checked: boolean = true;
  public headerIconSize: IconSize = IconSize.MEDIUM;

  protected solveRequirementText: ButtonStatus = {
    value: 'Solve',
    loading: false,
    icon: Icons.ONSCALE,
  };
  protected readonly InputTypes = InputTypes;

  // END MOVE TO OWN WIDGET
  protected defaultCols: (GridFillMode | SizeValue)[] | number | string = [
    { value: 320, unit: Unit.PX },
    GridFillMode.FILL,
    { value: 320, unit: Unit.PX },
  ];
  protected twoCols: (GridFillMode | SizeValue)[] | number | string = [
    { value: 320, unit: Unit.PX },
    GridFillMode.FILL,
  ];
  protected config: Grid = {
    columnGap: gridGap,
    columns: this.twoCols,
    rowGap: gridGap,
    rows: [GridFillMode.FILL],
  };
  protected sectionMap: Map<ConceptSections, SectionCardTab[]> = new Map([
    [ConceptSections.REQUIREMENT, requirementSections],
  ]);
  protected selectedSubSection: ConceptSubSection = ConceptSubSection.MAIN;
  protected readonly ConceptSections = ConceptSections;
  protected readonly ConceptSubSection = ConceptSubSection;
  protected readonly Icons = Icons;
  protected selectedSection!: ConceptSections;
  protected subsections: SectionCardTab[] = requirementSections;
  protected type: ButtonType = ButtonType.PRIMARY;
  protected size: ButtonSize = ButtonSize.LARGE;
  protected requirementIcon: IconType = {
    icon: Icons.PLOTS,
  };
  protected energyIcon: IconType = {
    icon: Icons.ELECTRICAL,
  };
  protected backgroundColor: ColorBackground = ColorBackground.DANGER_DEFAULT;
  protected textColor: ColorForeground = ColorForeground.NEUTRAL_DEFAULT;
  protected position: BadgePosition = BadgePosition.TOP_RIGHT;
  protected resultsBadgeText: string = '';
  protected cliVersion: string = '';
  private readonly statusMap: Map<
    string,
    { value: string; loading: boolean; icon: Icons }
  > = new Map([
    ['CREATING', { value: 'Job Creating', loading: true, icon: Icons.SPINNER }],
  ]);
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  /**
   * Study layout component constructor
   */
  constructor(
    private state: State,
    private requirementsService: RequirementsService,
    private dialogService: DialogService,
    protected architectureService: ArchitectureService,
    private actions: ActionContributionsService,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    protected conceptService: ConceptService,
    @Inject(TITLE) protected appName: string,
    private _cdr: ChangeDetectorRef,
    private requirementService: RequirementsService
  ) {
    const selectedSection = this.state.value(ActiveSectionState);
    if (selectedSection) {
      this.selectedSection = selectedSection.type as ConceptSections;
    }

    this.actions
      .get(SectionSelectAction)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((section?: WorkspaceTabSection) => {
        if (section) {
          this.selectedSection = section?.type as ConceptSections;
          this.handleSectionChange(section);
          this._cdr.detectChanges();
        }
      });
    const activeSection = this.state.value(ActiveSectionState);
    if (activeSection) {
      this.handleSectionChange(activeSection);
    }

    this.actions
      .get(UpdateJobStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((context: [string, string]) => {
        const [, status] = context;
        this.solveRequirementText = this.statusMap.get(status) || {
          value: 'Solve',
          loading: false,
          icon: Icons.ONSCALE,
        };
        this._cdr.detectChanges();
      });

    this.requirementService.selectedResultCliVersion
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cliVersion: any) => {
        this.cliVersion = cliVersion;
      });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.state.set(ActiveConceptState, undefined);
  }

  solveButtonClicked($event: Event): void {
    $event.preventDefault();
    const tab = this.state.value(ActiveTabState);
    const dialog = this.dialogService.open(StartSolveComponent, {
      title: 'Start Solve',
      data: {
        concept: this.state.value(ActiveConceptState),
        designId: tab?.id || '',
        confirm: (
          name: string,
          ids: string[],
          extraMemory: boolean = false
        ) => {
          this.solveRequirementText = {
            value: 'Job Creating',
            loading: true,
            icon: Icons.SPINNER,
          };
          dialog.close();
          this._cdr.detectChanges();

          this.requirementsService
            .solveRequirements(name, ids, extraMemory)
            .finally(() => {
              this.solveRequirementText = {
                value: 'Solve',
                loading: false,
                icon: Icons.ONSCALE,
              };
              this._cdr.detectChanges();
            });

          const eventData: AnalyticsEventPayload = {
            type: AnalyticEventEnum.REQUIREMENT_SOLVE_STARTED,
            data: { name, ids },
          };
          this.actions.execute(SubmitAnalyticsEventAction, eventData);
        },
      },
    });
  }

  protected redirectHandle($event: string): void {
    if ($event === this.selectedSubSection) return;
    this.selectedSubSection = $event as ConceptSubSection;
    if (this.selectedSection === ConceptSections.REQUIREMENT) {
      const a = this.state.value(ActiveSectionState);

      if (a && a.type === ConceptSections.REQUIREMENT) {
        const params: string[] | undefined =
          $event === ConceptSubSection.MAIN ? [] : ['results'];

        this.actions.execute(SectionSelectAction, {
          sectionId: a.id,
          urlParams: params,
        });
        if ($event === ConceptSubSection.MAIN) {
          this.requirementsService.jobResultLoading.next(JobLoadingStatus.IDLE);
        }
      }
    }
  }
  /**
   * Handles the section change by updating the sub sections and the selected subsection
   */
  private handleSectionChange(section: WorkspaceTabSection): void {
    this.subsections = this.sectionMap.get(this.selectedSection) || [];
    if (
      this.selectedSection === ConceptSections.REQUIREMENT &&
      section?.urlParams?.length
    ) {
      if (section.urlParams[0] === 'results') {
        this.selectedSubSection = ConceptSubSection.RESULTS;
      }
    } else {
      this.selectedSubSection = ConceptSubSection.MAIN;
    }
  }
}
