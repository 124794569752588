import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { ActionsEnum } from '../../shared/enums';
import { UnitType } from '../../../api';
import {
  AWCTableCell,
  AWCTableColumns,
  AWCTableRow,
} from '@ansys/awc-angular/tables';
import { ResultType } from '../../components/widgets/results-list/results-list.component';
import { ConceptUnitService } from '../../shared/services/unit.service';
import { ScientificNotationPipe } from '../../shared/pipes/scientific-notation/scientific-notation.pipe';
import { Injectable } from '@angular/core';
import { BuildComponentCellAction } from './build-component-cell.action';
import { MultiFileContent } from './download-files.action';

type Input = [AWCTableColumns[], any, { start: number; end: number }?];

@Injectable({
  providedIn: 'root',
})
export class ComposeFullDatasetAction
  implements AWFAction<Input, MultiFileContent>
{
  public readonly type = ActionsEnum.COMPOSE_FULL_DATASET;

  constructor(
    private unitChoices: ConceptUnitService,
    private snPipe: ScientificNotationPipe,
    private actions: ActionContributionsService
  ) {}

  execute: (context: Input) => Promise<MultiFileContent> = async (
    context: Input
  ) => {
    const [requirementColumns, result, range] = context;

    const headers: string[] = requirementColumns
      .map((col) => col.header)
      .filter((header): header is string => header !== undefined);

    const requirementColumnIds = requirementColumns.map((col) => col.id);
    const allComponentRows: { [key: string]: AWCTableRow[] } = {};

    for (const component of result.solved_components) {
      const componentRows: AWCTableRow[] = [];
      const numberOfPoints = this.getNumberOfPoints(
        component,
        requirementColumnIds
      );

      const start =
        range && range.start >= 0 && range.start <= numberOfPoints
          ? range.start
          : 0;

      const end =
        range && range.end >= start && range.end <= numberOfPoints
          ? range.end
          : numberOfPoints;

      for (let i = start; i <= end; i++) {
        const rowsForPoint: AWCTableRow = {
          id: i.toString(),
          cells: await this.actions.execute(BuildComponentCellAction, [
            component,
            requirementColumnIds,
            result.requirement_solved_type === ResultType.STATIC ? 0 : i,
          ]),
        };
        componentRows.push(rowsForPoint);
      }
      allComponentRows[component.name] = componentRows;
    }

    // parse this data to the appropriate CSV
    const csvFilesContent: MultiFileContent = {};
    Object.keys(allComponentRows).forEach((componentName: string) => {
      const tableData = allComponentRows[componentName];

      // Now we got the csv data - prepare the file content
      csvFilesContent[componentName] =
        headers.join(',') +
        '\n' +
        tableData
          .map((row: AWCTableRow) => {
            row.cells.shift();
            return row.cells.map((cell: AWCTableCell) => cell.value).join(',');
          })
          .join('\n');
    });

    return csvFilesContent;
  };

  private getNumberOfPoints(
    data: { [key: string]: any },
    numberOfPoints: string[]
  ): number {
    for (const key in data) {
      if (Array.isArray(data[key]) && numberOfPoints.includes(key)) {
        return data[key].length;
      }
    }

    return 0;
  }
}
