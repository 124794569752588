<ng-container *featureFlag="featureFlagsEnum.VIEW_SECTION_BREADCRUMBS" >
 @if (!conceptService.loadingErrorMessage()) {
      <div *ngIf="display && !isEditing" class="breadcrumb-cont">
        <div class="breadcrumb-inner">
          <span [awcTooltip]="projectTitle" *ngIf="!loading else loadingSkeleton" (dblclick)="onEditTitle('project')" class="pointer">{{ projectTitle }} </span>
          <span>/</span>
          <span *ngIf="!loading else loadingSkeleton" [awcTooltip]="branchTitle" (dblclick)="onEditTitle('branch')" class="pointer">{{ branchTitle }}</span>
          <span>/</span>
          <div *ngIf="!loading else loadingSkeleton" [awcTooltip]="versionTitle" class="version-title">
            {{ versionTitle }}
          </div>
        </div>
      </div>
      <div *ngIf="display && isEditing" class="breadcrumb-cont">
        <awc-input
          class="breadcrumb-edit"
          (keydown.enter)="finishEditing()"
          (keydown.esc)="cancelEditing()"
          (click)="$event.stopPropagation()"
          [(value)]="editValue"
          (valueChange)="editValue = $event.toString()"
        ></awc-input>
        <div class="overlay" *ngIf="display && isEditing" (click)="finishEditing()"></div>
      </div>

    <ng-template #loadingSkeleton>
      <awc-loading-skeleton
        [width]="width"
        [lineHeight]="lineHeight"
        [type]="type"
      ></awc-loading-skeleton>
    </ng-template>
  }
</ng-container>

