import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { DIALOG_DATA, DIALOG_REF } from '@ansys/andromeda/shared';
import { ConceptSections, PlotConfigType } from '../../../shared/enums';
import { ResultType } from '../../widgets/results-list/results-list.component';
import { FlexLayout } from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { RequirementsService } from '../../../shared/services/requirements.service';
import { lastValueFrom, take } from 'rxjs';
import {
  ComposeFullDatasetAction,
  ComposeSingleDatasetAction,
  DownloadFilesAction,
} from '../../../actions';
import {
  AWCTableColumns,
  AWCTableRow,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { ButtonSize, ButtonType } from '@ansys/awc-angular/buttons';
import { PlotService } from '../../../shared/services/plot.service';
import { Icons, IconType } from '@ansys/awc-angular/icons';
import { ConceptUnitService } from '../../../shared/services/unit.service';
import { InputTypes } from '../../widgets/forms/input/input.component';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import { State } from '@ansys/andromeda/store';
import { ProjectApiService } from '@ansys/cloud-angular-client';
import {
  DefaultProjectFileName,
  ProjectDateStringAction,
  ProjectNameAction,
} from '../../../actions/lib/common-strings.action';

@Component({
  selector: 'app-download-dataset',
  templateUrl: './download-dataset.component.html',
  styleUrls: ['./download-dataset.component.scss'],
})
export class DownloadDatasetComponent {
  protected layout: FlexLayout = FlexLayout.ROW;
  protected pointIndexes: AWCListItem[] = [];
  protected selectedPointIndex: string[] = [];
  protected readonly sections = ConceptSections;
  protected readonly resultType = ResultType;
  protected selectedRequirementColumns: string[] = ['speeds'];
  protected size: ButtonSize = ButtonSize.SMALL;
  protected type: ButtonType = ButtonType.SECONDARY;
  protected plotConfigType: PlotConfigType = PlotConfigType.NONE;
  protected activeResult: any;
  protected columns: AWCTableColumns[] = [];
  protected downloadIcon: IconType = {
    icon: Icons.DOWNLOAD,
  };
  protected rows: AWCTableRow[] = [];
  protected hasDatasetRangeSet: boolean = false;
  protected rangeStart: number = 0;
  protected rangeEnd: number = 0;
  protected readonly inputType = InputTypes;
  private sourceFileNme: string = '';
  private dateString: string = '';

  constructor(
    @Inject(DIALOG_DATA) private data: any,
    @Inject(DIALOG_REF) private ref: any,
    private requirementService: RequirementsService,
    private plotService: PlotService,
    private _cdr: ChangeDetectorRef,
    private actions: ActionContributionsService,
    protected units: ConceptUnitService
  ) {
    // Set active result
    this.requirementService.selectedResult.subscribe((result: any) => {
      this.activeResult = result;
      this.plotConfigType = PlotConfigType.REQUIREMENTS;
      this.buildPointSelections();
    });

    this.plotService.activeTableData.subscribe((data) => {
      [this.columns, this.rows, this.selectedRequirementColumns] = data;
      this._cdr.markForCheck();
    });
  }

  protected setStartRange($event: string | number): void {
    this.rangeStart = $event as number;
    this._cdr.detectChanges();

    if (this.rangeEnd) {
      this.rangeStart = Math.min(Math.max($event as number, 0), this.rangeEnd);
    }
  }

  protected setEndRange($event: string | number): void {
    this.rangeEnd = $event as number;
    this._cdr.detectChanges();

    this.rangeEnd = Math.min(
      Math.max($event as number, this.rangeStart),
      this.pointIndexes.length
    );
  }

  protected downloadFullDataset(range?: { start: number; end: number }): void {
    const pointColumn: AWCTableColumns = {
      id: 'point',
      header: 'point name..',
      type: TableCellTypes.TEXT,
    };
    const modifiedColumns = [pointColumn, ...this.columns.slice(1)];

    this.requirementService.selectedResult
      .pipe(take(1))
      .subscribe(async (results: any) => {
        const csvFilesContent = await this.actions.execute(
          ComposeFullDatasetAction,
          [modifiedColumns, results, range]
        );

        const fileNameSuffix = await this.actions.execute(
          DefaultProjectFileName
        );
        const fileNamePrefix = range ? 'Partial Dataset' : 'Full Dataset';
        // with the file content, download it
        await this.actions.execute(DownloadFilesAction, [
          `${fileNamePrefix} - ${fileNameSuffix}.zip`,
          csvFilesContent,
        ]);
      });
  }

  protected async downloadSingleDataset(): Promise<void> {
    const csvContent = await this.actions.execute(ComposeSingleDatasetAction, [
      this.columns,
      this.rows,
    ]);

    const fileNameSuffix = await this.actions.execute(DefaultProjectFileName);
    const filename = `${
      this.activeResult.requirement_solved_type !== this.resultType.STATIC
        ? `Point ${this.selectedPointIndex} Data - ${fileNameSuffix}`
        : fileNameSuffix
    }.csv`;

    // with the file content, download it
    await this.actions.execute(DownloadFilesAction, [filename, csvContent]);
  }

  protected changePointSelected($event: AWCListItem[]): void {
    this.selectedPointIndex = $event.map((e) => e.id);

    this.requirementService.selectedTimeIndex.next(this.selectedPointIndex[0]);
    this.requirementService.solvedComponentTable(
      this.activeResult,
      parseInt(this.selectedPointIndex[0]),
      this.selectedRequirementColumns
    );
  }

  private buildPointSelections(): void {
    if (!this.activeResult) {
      return;
    }

    const mapArray =
      this.activeResult.distance ?? this.activeResult.traction_limits;

    if (!mapArray) {
      return;
    }

    this.pointIndexes = mapArray.map((c: unknown, i: number) => {
      return {
        id: i.toString(),
        text: `Point: ${i.toString()}`,
      };
    });

    if (!this.hasDatasetRangeSet) {
      this.hasDatasetRangeSet = true;
      this.rangeEnd = this.pointIndexes.length;
    }

    this.selectedPointIndex = [
      this.requirementService.selectedTimeIndex.value.toString(),
    ];
  }
}
