import { Inject, Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { State } from '@ansys/andromeda/store';
import { ActiveTabState } from '@ansys/andromeda/workspace';
import { SelectionService } from '../../shared/services/selection.service';
import { InverterDataState } from '../../state/lib/inverter-data.state';
import { ComponentsService } from 'src/app/shared/services/components.service';
import {
  DataDisplayService,
  DataDisplayState,
} from '../../shared/services/data-display.service';
import { MotorLossTypeState } from '../../state/lib/motor-loss-type.state';
import { MotorSpeedState } from '../../state/lib/motor-speed.state';
import {
  InverterComponentOptionsState,
  InverterOptions,
} from '../../state/lib/inverter-component-options.state';
import { ConceptInputType } from '../../shared/enums';
import { InverterLossesType } from '../../shared/enums/inverter-losses-type';

type InputType = {
  value: string | number;
  type: string;
};
type ReturnType = void;

/*
 * Inject the state service to access the application state.
 * If an action needs to access or update the state,
 * it can be done using the state service.Actions can trigger other actions as well by injecting the action contribution service.
 */
@Injectable({
  providedIn: 'root',
})
export class SetInverterDataActionAction
  implements AWFAction<InputType, ReturnType>
{
  constructor(
    private state: State,
    private selectionService: SelectionService,
    private componentService: ComponentsService,
    protected dataDisplay: DataDisplayService
  ) {}

  async execute(context: InputType): Promise<ReturnType> {
    const { value, type } = context;

    this.state.update(
      InverterComponentOptionsState,
      (
        inverterOptions = {
          lossType: InverterLossesType.TOTAL,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dc_current: 50,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          phase_current_max: 400,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          power_factor: 1,
          frequency: 1000,
        }
      ) => {
        inverterOptions[type as keyof InverterOptions] = value;
        return inverterOptions;
      }
    );

    this.dataDisplay.displayState = DataDisplayState.LOADING;

    const filteredItems = this.selectionService.getSelectedItems() ?? [];
    const selectedItem = filteredItems[0];

    if (!selectedItem || !selectedItem.userData) return;

    const activeTab = this.state.value(ActiveTabState);

    let extOpts = {};

    if (selectedItem.userData['component_type'] === 'MotorLabModel') {
      extOpts = {
        lossType: this.state.value(MotorLossTypeState, activeTab?.id || ''),
        speed: this.state.value(MotorSpeedState, activeTab?.id || ''),
      };
    } else if (
      selectedItem.userData['component_type'] === 'InverterAnalytical'
    ) {
      extOpts = {
        ...this.state.value(InverterComponentOptionsState),
      };
    }

    try {
      const options = {
        type: ConceptInputType.INVERTER,
        ...extOpts,
        lossType:
          this.state.value(InverterComponentOptionsState)?.['lossType'] ||
          InverterLossesType.TOTAL,
      };

      await this.componentService.displayData(
        selectedItem.id as string,
        options
      );

      this.dataDisplay.displayState = DataDisplayState.GRAPH_DISPLAY;
    } catch {
      /* empty */
    }
  }
}
