import { Component, Inject } from '@angular/core';
import { LibraryService } from '@ansys/andromeda/dashboard';
import {
  DIALOG_DATA,
  DIALOG_REF,
  ProductContextService,
  SnackBarService,
} from '@ansys/andromeda/shared';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { LibraryService as LibraryAPIService } from '../../../../api/services/LibraryService';
import { SelectedAccountState, State } from '@ansys/andromeda/store';
import { ActiveConceptState } from '../../../state/lib/active-concept.state';
import {
  type Aero,
  type AncillaryLoad,
  type BatteryFixedVoltages,
  type BatteryLookupTable,
  type BatteryLookupTableID,
  ConceptPopulated,
  type DecelerationLimit,
  type DisconnectClutchInput,
  type InverterAnalytical,
  type Mass,
  type MotorCTCP,
  type MotorLab,
  type MotorLabID,
  type MotorLossMap,
  type MotorLossMapID,
  type MotorTorqueCurves,
  type MotorTorqueCurvesID,
  type TransmissionLossCoefficients,
  type TransmissionLossMap,
  type TransmissionLossMapID,
  type TransmissionNeglect,
  type WheelInput,
} from '../../../../api';
import { lastValueFrom } from 'rxjs';
import { UploadFileResponseType } from '../../../shared/types/upload-file-response.type';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-library-export',
  templateUrl: './library-export.component.html',
  styleUrls: ['./library-export.component.scss'],
})
export class LibraryExportComponent {
  protected description: string = '';
  protected name: string = '';

  constructor(
    private libraryAPIService: LibraryAPIService,
    @Inject(DIALOG_DATA)
    protected data: {
      type?: string;
      item?: AWCTreeItem;
      blob?: Blob;
      requestBody?:
        | (
            | BatteryFixedVoltages
            | BatteryLookupTable
            | MotorCTCP
            | MotorTorqueCurves
            | MotorLossMap
            | MotorLab
            | TransmissionLossCoefficients
            | TransmissionLossMap
            | TransmissionNeglect
            | InverterAnalytical
            | DisconnectClutchInput
            | MotorLossMapID
            | MotorLabID
            | MotorTorqueCurvesID
            | BatteryLookupTableID
            | TransmissionLossMapID
          )
        | (Aero | Mass | WheelInput | DecelerationLimit | AncillaryLoad);
      uploadedFile?: UploadFileResponseType;
    },
    @Inject(DIALOG_REF) private dialogRef: DialogComponent,
    private snackbar: SnackBarService,
    private productContextService: ProductContextService,
    private state: State,
    private notificationService: NotificationService
  ) {
    this.name = this.data.item?.text || '';
  }

  protected async confirmExport(): Promise<void> {
    this.snackbar.info('Exporting Resource');
    const concept = this.state.value(ActiveConceptState);
    if (concept) {
      const product = await this.productContextService.getProduct();
      if (product) {
        const accountId = this.state.value(SelectedAccountState);

        let asset;
        if (!this.data.requestBody && this.data.item) {
          asset = await lastValueFrom(
            this.libraryAPIService.addToLibraryLibraryFromIdPost(
              this.data.item.id,
              accountId,
              product.partNumber,
              this.description,
              concept.design_instance_id,
              this.name
            )
          ).catch((err) => {
            this.notificationService.error('Error Adding To Library', err);
            throw new Error(err);
          });
        } else if (this.data.requestBody) {
          const data = this.data.uploadedFile
            ? // eslint-disable-next-line @typescript-eslint/naming-convention
              { data_id: this.data.uploadedFile?.id }
            : {};

          asset = await lastValueFrom(
            this.libraryAPIService.addToLibraryDirectLibraryDirectUploadPost(
              accountId,
              product.partNumber,
              this.description,
              concept.design_instance_id,
              {
                ...this.data.requestBody,
                ...data,
              }
            )
          ).catch((err) => {
            this.notificationService.error('Error Adding To Library', err);
            throw new Error(err);
          });
        }

        if (asset) {
          this.snackbar.success('Resource Exported');
        } else {
          this.snackbar.error('Resource Export Failed');
        }
      }

      this.dialogRef.close();
    }
  }

  protected cancel(): void {
    this.dialogRef.close();
  }

  protected stopProp($event: Event): void {
    $event.stopPropagation();
  }
}
