import { Injectable } from '@angular/core';
import {
  ActionContributionsService,
  AWFAction,
} from '@ansys/andromeda/contributions';
import { ActionsEnum } from '../actions.enum';
import { lastValueFrom } from 'rxjs';
import { ActiveConceptState } from '../../state/lib/active-concept.state';
import { ProjectApiService } from '@ansys/cloud-angular-client';
import { State } from '@ansys/andromeda/store';

type InputType = string;
type ReturnType = string;

@Injectable({ providedIn: 'root' })
export class ProjectNameAction implements AWFAction<InputType, ReturnType> {
  public readonly type = ActionsEnum.PROJECT_NAME;

  constructor(private projectApi: ProjectApiService, private state: State) {}

  execute: (context: InputType) => Promise<ReturnType> = async () => {
    const projectLoaded = await lastValueFrom(
      this.projectApi.projectLoadHandler({
        projectId: this.state.value(ActiveConceptState)?.project_id || '',
      })
    );

    return projectLoaded.projectTitle || 'Project';
  };
}

@Injectable({ providedIn: 'root' })
export class ProjectDateStringAction
  implements AWFAction<InputType, ReturnType>
{
  public readonly type = ActionsEnum.PROJECT_DATE_STRING;

  execute: (context: InputType) => Promise<ReturnType> = async () => {
    return `${new Date()
      .toISOString()
      .substring(0, 10)} ${new Date().toLocaleTimeString()} ${
      new Date()
        .toLocaleTimeString('en', { timeZoneName: 'short' })
        .split(' ')[2]
    }`;
  };
}

@Injectable({ providedIn: 'root' })
export class DefaultProjectFileName
  implements AWFAction<InputType, ReturnType>
{
  public readonly type = ActionsEnum.DEFAULT_PROJECT_FILE_NAME;

  constructor(private actions: ActionContributionsService) {}

  execute: (context: InputType) => Promise<ReturnType> = async () => {
    const sourceFileNme = await this.actions.execute(ProjectNameAction);
    const dateString = await this.actions.execute(ProjectDateStringAction);

    return sourceFileNme + ' - ' + dateString;
  };
}
