/* eslint-disable node/no-unpublished-import */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonComponent } from '@ansys/awc-angular/buttons';
import { GridComponent } from '@ansys/awc-angular/layouts';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { LabelComponent, SwitchComponent } from '@ansys/awc-angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipComponent } from '@ansys/awc-angular/chips';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { PopoutComponent } from '@ansys/awc-angular/popups';
import { ButtonGroupComponent } from '@ansys/awc-angular/buttons';
import { TreeviewComponent } from './components/widgets/treeview/treeview.component';

import { ConceptLayoutComponent } from './components/layout/concept-layout/concept-layout.component';
import { WorkspaceComponent } from '@ansys/andromeda/workspace';
import {
  DashboardModule,
  DashboardPanelComponent,
  TextareaComponent,
} from '@ansys/andromeda/dashboard';
import { ActiveSelectionEditInputsComponent } from './components/widgets/active-selection-edit-inputs/active-selection-edit-inputs.component';
import { GraphDisplayComponent } from './components/widgets/charts/graph-display/graph-display.component';
import { TableDisplayComponent } from './components/widgets/charts/table-display/table-display.component';
import { MotorArchitectureComponent } from './components/widgets/motor-architecture/motor-architecture.component';
import { DataDisplayPanelComponent } from './components/layout/data-display-panel/data-display-panel.component';
import { AddPartComponent } from './components/dialogs/add-part/add-part.component';
import { SelectComponent } from '@ansys/awc-angular/forms';
import { PartInputsComponent } from './components/widgets/part-inputs/part-inputs.component';
import { NoDataDisplayComponent } from './components/widgets/no-data-display/no-data-display.component';
import { InputComponent } from './components/widgets/forms/input/input.component';
import { ConceptTitleComponent } from './components/widgets/concept-title/concept-title.component';
import { ConceptNavigationToolsComponent } from './components/widgets/concept-navigation-tools/concept-navigation-tools.component';
import {
  LoadingSkeletonComponent,
  ProgressBarComponent,
} from '@ansys/awc-angular/loaders';
import { SessionModule } from '@ansys/andromeda/auth';
import {
  ContributionsTableComponent,
  PageComponent,
} from '@ansys/andromeda/shared';
import { UserSocketService } from '@ansys/andromeda/sockets';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { ResultsListComponent } from './components/widgets/results-list/results-list.component';
import { StartSolveComponent } from './components/dialogs/start-solve/start-solve.component';
import { ActiveJobDisplayComponent } from './components/widgets/active-job-display/active-job-display.component';
import { AccordionComponent } from '@ansys/awc-angular/accordions';
import { NoResultsComponent } from '@ansys/andromeda/dashboard';
import { ChartComponent } from '@ansys/awc-angular/charts';
import { TableComponent } from '@ansys/awc-angular/tables';
import { SectionCardComponent } from './components/layout/section-card/section-card.component';
import { ScientificNotationPipe } from './shared/pipes/scientific-notation/scientific-notation.pipe';
import { DatePipe } from '@angular/common';
import { UnitsComponent } from './components/dialogs/units/units.component';
import { BlueprintDisplayComponent } from './components/widgets/charts/blueprint-display/blueprint-display.component';
import { MarkerDisplayComponent } from './components/widgets/charts/blueprint-display/marker-display/marker-display.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutService } from './shared/services/layout.service';
import { UnitFromTypePipe } from './shared/pipes/unit-from-type/unit-from-type.pipe';
import { AerodynamicsComponent } from './components/widgets/part-inputs/inputs/aerodynamics/aerodynamics.component';
import { MassComponent } from './components/widgets/part-inputs/inputs/mass/mass.component';
import { WheelsComponent } from './components/widgets/part-inputs/inputs/wheels/wheels.component';
import { ClutchComponent } from './components/widgets/part-inputs/inputs/clutch/clutch.component';
import { BatteryComponent } from './components/widgets/part-inputs/inputs/battery/battery.component';
import { TransmissionComponent } from './components/widgets/part-inputs/inputs/transmission/transmission.component';
import { InverterComponent } from './components/widgets/part-inputs/inputs/inverter/inverter.component';
import { StaticRequirementsComponent } from './components/widgets/part-inputs/inputs/static-requirements/static-requirements.component';
import { DynamicRequirementsComponent } from './components/widgets/part-inputs/inputs/dynamic-requirements/dynamic-requirements.component';
import { DriveCyclesComponent } from './components/widgets/part-inputs/inputs/drive-cycles/drive-cycles.component';
import { DataConfigComponent } from './components/widgets/data-config/data-config.component';
import { ArchitectureConfigurationsComponent } from './components/widgets/architecture-configurations/architecture-configurations.component';
import { LibraryPanelComponent } from './components/widgets/library-panel/library-panel.component';
import { LibraryExportComponent } from './components/dialogs/library-export/library-export.component';
import { ConceptSelectComponent } from './components/widgets/forms/select/select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ImportLibraryAssetComponent } from './components/dialogs/import-library-asset/import-library-asset.component';
import { DownloadDatasetComponent } from './components/dialogs/download-dataset/download-dataset.component';
import { AndromedaModule } from './andromeda.module';
import { MotorComponent } from './components/widgets/part-inputs/inputs/motor/motor.component';
import { ArchitectureDataPanelComponent } from './components/widgets/architecture-data-panel/architecture-data-panel.component';
import { ImportDriveCycleComponent } from './components/dialogs/import-drive-cycle/import-drive-cycle.component';

@NgModule({
  declarations: [
    AppComponent,
    TreeviewComponent,
    ConceptLayoutComponent,
    ActiveSelectionEditInputsComponent,
    GraphDisplayComponent,
    TableDisplayComponent,
    MotorArchitectureComponent,
    DataDisplayPanelComponent,
    AddPartComponent,
    PartInputsComponent,
    NoDataDisplayComponent,
    ConceptNavigationToolsComponent,
    ConfirmComponent,
    ActiveJobDisplayComponent,
    SectionCardComponent,
    UnitsComponent,
    BlueprintDisplayComponent,
    MarkerDisplayComponent,
    AerodynamicsComponent,
    MassComponent,
    WheelsComponent,
    ClutchComponent,
    BatteryComponent,
    TransmissionComponent,
    MotorComponent,
    InverterComponent,
    StaticRequirementsComponent,
    DynamicRequirementsComponent,
    DriveCyclesComponent,
    DataConfigComponent,
    ArchitectureConfigurationsComponent,
    LibraryExportComponent,
    ConceptSelectComponent,
    DownloadDatasetComponent,
    ImportLibraryAssetComponent,
    LibraryPanelComponent,
    MotorComponent,
    ImportDriveCycleComponent,
  ],
  imports: [
    ArchitectureDataPanelComponent,
    InputComponent,
    ResultsListComponent,
    HttpClientModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    SwitchComponent,
    GridComponent,
    ButtonComponent,
    SessionModule,
    BrowserAnimationsModule,
    ChipComponent,
    DialogComponent,
    PopoutComponent,
    ButtonGroupComponent,
    WorkspaceComponent,
    StartSolveComponent,
    SelectComponent,
    DashboardPanelComponent,
    DashboardModule,
    LoadingSkeletonComponent,
    AccordionComponent,
    NoResultsComponent,
    ChartComponent,
    DragDropModule,
    TableComponent,
    SessionModule,
    TextareaComponent,
    ProgressBarComponent,
    ScrollingModule,
    PageComponent,
    LabelComponent,
    ContributionsTableComponent,
    ConceptTitleComponent,
    AndromedaModule,
  ],
  providers: [
    UserSocketService,
    DatePipe,
    ScientificNotationPipe,
    UnitFromTypePipe,
    LayoutService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
