import { Component, Inject } from '@angular/core';
import {
  DIALOG_DATA,
  DIALOG_REF,
  TableRefreshDataAction,
} from '@ansys/andromeda/shared';
import { DialogComponent } from '@ansys/awc-angular/popups';
import { InputComponent, TextareaComponent } from '@ansys/awc-angular/forms';
import {
  ButtonComponent,
  ButtonSize,
  ButtonType,
} from '@ansys/awc-angular/buttons';
import { ConceptService } from '../../../shared/services/concept.service';
import { DashboardMenus, DashboardTables } from '@ansys/andromeda/dashboard';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { ComponentWidth } from '@ansys/awc-angular/core';

@Component({
  selector: 'app-project-rename-dialog',
  standalone: true,
  imports: [InputComponent, ButtonComponent, TextareaComponent],
  templateUrl: './project-rename-dialog.component.html',
  styleUrl: './project-rename-dialog.component.scss',
})
export class ProjectRenameDialogComponent {
  protected title: string = '';
  protected projectName: string = '';
  protected projectGoal: string = '';
  protected loading: boolean = false;
  protected type: ButtonType = ButtonType.SECONDARY;
  protected size: ButtonSize = ButtonSize.MEDIUM;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;

  constructor(
    @Inject(DIALOG_DATA) protected data: any,
    @Inject(DIALOG_REF) private dialogRef: DialogComponent,
    private conceptService: ConceptService,
    private actionService: ActionContributionsService
  ) {
    this.projectName = data.title;
    this.projectGoal = data.goal;
  }

  async confirm(): Promise<void> {
    this.loading = true;
    await this.conceptService.updateProject(
      this.projectName,
      this.projectGoal,
      this.data.id
    );
    this.actionService.execute(TableRefreshDataAction, DashboardTables.PROJECT);
    this.loading = false;
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
