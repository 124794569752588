<div class="dialog-cont">
  <awc-input
    label="Project Name"
    [(value)]="projectName"
    (keydown.enter)="confirm()"
    (valueChange)="projectName = $event.toString()"
  ></awc-input>
  <awc-textarea
    label="Project Goal"
    placeholder="Add a goal for your project"
    [(value)]="projectGoal"
    [lines]="5"
    (changes)="projectGoal = $event"></awc-textarea>

  <awc-button
    text="Update"
    [type]="type"
    [size]="size"
    [awcSizing]="awcSizing"
    [disabled]="loading"
    (clicked)="confirm()"
  ></awc-button>
</div>
