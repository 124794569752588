/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, ElementRef, Signal } from '@angular/core';
import { Icons } from '@ansys/awc-angular/icons';
import { ResultType } from '../results-list/results-list.component';
import { ColorBackground } from '@ansys/awc-angular/core';
import { AeroInDB, MassInDB, UnitType, WheelInDB } from 'src/api';
import { InputTypes } from '../forms/input/input.component';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { ConceptUnitService } from 'src/app/shared/services/unit.service';

@Component({
  selector: 'app-active-job-display',
  templateUrl: './active-job-display.component.html',
  styleUrls: ['./active-job-display.component.scss'],
})
export class ActiveJobDisplayComponent {
  @Input() result: any;
  readonly Icons = Icons;
  readonly ResultType = ResultType;
  readonly InputTypes = InputTypes;
  readonly UnitType = UnitType;
  protected backgroundColor: ColorBackground =
    ColorBackground.NEUTRAL_BACKGROUND_1;
  protected requirement: any;
  protected aero!: AeroInDB;
  protected mass!: MassInDB;
  protected wheel!: WheelInDB;
  protected maxHeight!: Signal<number>;
  protected componentCost: number = 0;
  protected componentMass: number = 0;
  protected dynamicRequiredTime: number | undefined;
  protected dynamicRequiredDistance: number | undefined;

  constructor(
    private elRef: ElementRef,
    private layoutService: LayoutService,
    protected units: ConceptUnitService
  ) {
    this.maxHeight = this.layoutService.innerCardHeight;
  }

  ngOnInit(): void {
    this.destructureResult();
  }

  ngOnChanges(): void {
    this.destructureResult();
  }

  protected destructureResult(): void {
    if (this.result) {
      this.getComponentCossAndMass();
      ({
        requirement: this.requirement,
        requirement: { aero: this.aero, mass: this.mass, wheel: this.wheel },
      } = this.result);
      if (this.result.requirement_solved_type === ResultType.DYNAMIC) {
        this.dynamicRequiredTime = this.result.requirement.max_capability
          ? this.result.requirement.required_time
          : undefined;
        this.dynamicRequiredDistance = this.result.requirement.max_capability
          ? this.result.requirement.required_distance
          : undefined;
      }
    }
  }

  protected number(value: number | string | unknown): number {
    return value as number;
  }

  private getComponentCossAndMass(): void {
    this.componentCost = 0;
    this.componentMass = 0;
    let components: any;
    if (this.result) {
      if (this.result.solved_components[0][0]) {
        components = this.result.solved_components[0];
      } else if (this.result.solved_components[0]) {
        components = this.result.solved_components;
      }
      components.forEach((component: any) => {
        this.componentCost += component.cost;
        this.componentMass += component.mass;
      });
    }
  }
}
