/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UnitType,
  DriveCycleRequirementIds,
  ConceptPopulated,
  Concept,
} from 'src/api';
import { InputTypes } from '../../../forms/input/input.component';
import {
  ColorBackground,
  ComponentWidth,
  FlexLayout,
} from '@ansys/awc-angular/core';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import {
  InputSize,
  InputType,
  LabelPosition,
  SwitchSize,
} from '@ansys/awc-angular/forms';
import { Icons } from '@ansys/awc-angular/icons';
import { State } from '@ansys/andromeda/store';
import { Subject, takeUntil } from 'rxjs';
import {
  AWFLocation,
  AWF_LOCATION,
  DialogService,
} from '@ansys/andromeda/shared';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import { ConceptService } from '../../../../../shared/services/concept.service';
import { ActiveConceptState } from '../../../../../state/lib/active-concept.state';
import { ImportDriveCycleComponent } from '../../../../dialogs/import-drive-cycle/import-drive-cycle.component';
import { EventBus } from '../../../../../shared/tools/event-bus';
import {
  DataDisplayService,
  DataDisplayState,
} from '../../../../../shared/services/data-display.service';

type SetPart = [value: string, type: string, id: string, index?: number];

@Component({
  selector: 'app-drive-cycles-inputs',
  templateUrl: './drive-cycles.component.html',
  styleUrls: ['../inputs.scss'],
})
export class DriveCyclesComponent implements OnInit {
  @Input() part!: DriveCycleRequirementIds;
  @Input() update!: boolean;
  @Output() setPart: EventEmitter<SetPart> = new EventEmitter<SetPart>();
  @Output() forceUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Output() partsLoaded: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('fileUpload') upload!: ElementRef;
  protected UnitType = UnitType;
  protected InputTypes = InputTypes;
  protected layout: FlexLayout = FlexLayout.COLUMN;
  protected aerodynamics: AWCListItem[] = [];
  protected masses: AWCListItem[] = [];
  protected wheels: AWCListItem[] = [];
  protected drivecycles: AWCListItem[] = [];
  protected limits: AWCListItem[] = [];
  protected loads: AWCListItem[] = [];
  protected readonly ColorBackground = ColorBackground;
  protected readonly InputType = InputType;
  protected selectedAero: string[] = [];
  protected selectedMass: string[] = [];
  protected selectedWheel: string[] = [];
  protected selectedLimit: string[] = [];
  protected selectedLoad: string[] = [];
  protected selectedDC!: string;
  protected selectedDCName!: string;
  protected labelPosition: LabelPosition = LabelPosition.BEFORE;
  protected size: SwitchSize = SwitchSize.MEDIUM;
  protected awcSizing: ComponentWidth = ComponentWidth.FILL;
  protected iconButton: Icons = Icons.ADD_GEOMETRY;
  protected partsLoadedEmitted: boolean = false;
  protected readonly InputSize = InputSize;
  protected readonly Icons = Icons;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private previousDriveCycleId: string | null = null;
  private bus: EventBus = EventBus.getInstance();

  constructor(
    private configurationService: ConfigurationsService,
    private conceptService: ConceptService,
    private state: State,
    @Inject(AWF_LOCATION) private location: AWFLocation,
    private dialogService: DialogService,
    private displayService: DataDisplayService
  ) {
    this.state
      .get(ActiveConceptState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (concept?: ConceptPopulated) => {
        if (!concept) return;

        this.getDriveCycleData(concept);
      });

    this.bus.register('drive-cycle:select:update', async () => {
      this.selectedDCName = this.getDriveCycleName();

      if (!this.selectedDCName) {
        // If we have a newly uploaded file, we'll have to re-fetch the drive cycle data
        const concept = this.state.value(ActiveConceptState);
        if (concept) {
          await this.getDriveCycleData(concept);
        }

        this.selectedDCName = this.getDriveCycleName();
      }

      this.selectedDC = this.part.drive_cycle_id;
      this.previousDriveCycleId = this.part.drive_cycle_id;
      this.displayService.displayState = DataDisplayState.GRAPH_DISPLAY;
    });
  }

  async getDriveCycleData(concept: ConceptPopulated): Promise<void> {
    const driveCycles = await this.conceptService.getConceptDriveCycles(
      concept
    );
    this.drivecycles = driveCycles.map((driveCycle) => {
      return { id: driveCycle[0], text: driveCycle[1] } as AWCListItem;
    });
    this.partsLoadedEmitted = true;
    this.partsLoaded.emit(this.partsLoadedEmitted);
    if (this.part?.drive_cycle_id) {
      this.selectedDCName = this.drivecycles.find((driveCycle) => {
        return driveCycle.id === this.part.drive_cycle_id;
      })?.text as string;
    }
    return Promise.resolve();
  }

  ngOnInit(): void {
    this.partsLoaded.emit(this.partsLoadedEmitted);
    this.selectedAero = [this.part.aero_id];
    this.selectedMass = [this.part.mass_id];
    this.selectedWheel = [this.part.wheel_id];
    this.selectedLoad = [this.part.ancillary_load_id ?? ''];
    this.selectedLimit = [this.part.deceleration_limit_id ?? ''];
    this.selectedDC = this.part.drive_cycle_id;

    [this.aerodynamics, this.masses, this.wheels, this.limits, this.loads] =
      this.configurationService.configurationLists.map((list) => {
        return list.map((item) => {
          const clone = { ...item };
          delete (clone as AWCTreeItem).parent;
          return clone;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected selectionIDSet($event: string, type: string): void {
    this.part[type as keyof DriveCycleRequirementIds] = $event as never;
    this.forceUpdate.emit('');
  }

  protected addDriveCycle(): void {
    this.dialogService.open(ImportDriveCycleComponent, {
      data: {
        selectedDriveCycle: this.selectedDC,
        part: this.part,
        onUpdate: () => {
          this.forceUpdate.emit('');
        },
      },
    });
  }

  protected toggleIFullRange($event: boolean): void {
    this.part.full_range_calculation = $event;
    this.forceUpdate.emit('');
  }

  private getDriveCycleName(): string {
    return this.drivecycles.find((driveCycle) => {
      return driveCycle.id === this.part.drive_cycle_id;
    })?.text as string;
  }
}
