/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { AWCTreeItem } from '@ansys/awc-angular/trees';
import {
  Concept,
  ConceptCloneInput,
  ConceptPopulated,
  ConceptsService,
  DriveCyclesService,
  OpenAPI,
  UnitChoicesService,
} from 'src/api';
import { AuthTokenState, State } from '@ansys/andromeda/store';
import {
  Design,
  DesignApiService,
  DesignInstance,
  ProjectApiService,
} from '@ansys/cloud-angular-client';
import { TreeService } from './tree.service';
import { lastValueFrom, Observable } from 'rxjs';
import { AuthSessionService } from '@ansys/andromeda/auth';
import { AWCListItem } from '@ansys/awc-angular/lists';
import { ConceptUnitService } from './unit.service';

import { ArchitectureService } from './architecture.service';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import {
  ActiveTabState,
  SectionUpdateAction,
} from '@ansys/andromeda/workspace';
import { LoadAllJobs, SetJobs, UpdateInputs } from 'src/app/actions';
import { ActiveConceptState } from 'src/app/state/lib/active-concept.state';
import { ComponentsState } from 'src/app/state/lib/components.state';
import { ConfigurationsService } from './configurations.service';
import { ComponentsService } from './components.service';
import { RequirementsService } from './requirements.service';
import { sectionState } from '../models/section-state';
import {
  AWFNotificationProvider,
  DialogService,
  NOTIFICATION_PROVIDER,
  SnackBarService,
  TableRefreshDataAction,
} from '@ansys/andromeda/shared';
import {
  AWCTableRow,
  TableCellAlign,
  TableCellTypes,
} from '@ansys/awc-angular/tables';
import { Icons } from '@ansys/awc-angular/icons';
import { PartType } from '../enums';
import { inverterSelectTypes } from '../models/lists/select-options/inverter-types.model';
import { batteryComponent } from '../models/concept-components/battery.model';
import { clutchComponent } from '../models/concept-components/clutch.model';
import { transmissionComponent } from '../models/concept-components/transmission.model';
import { transmissionSelectTypes } from '../models/lists/select-options/tranmission-types.model';
import { motorTorque } from '../models/concept-components/motor-torque-curve.model';
import { motorLossMap } from '../models/concept-components/motor-loss-map.model';
import { motorLabModel } from '../models/concept-components/motor-lab.model';
import { MapModelTypes } from '../types/map-model-types.type';
import { batterySelectTypes } from '../models/lists/select-options/battery-types.model';
import { aeroConfig } from '../models/concept-configurations/aero.model';
import { massConfig } from '../models/concept-configurations/mass.model';
import { ancillaryConfig } from '../models/concept-configurations/ancillary.model';
import { decelerationConfig } from '../models/concept-configurations/deceleration-limit.model';
import { staticRequirement } from '../models/concept-requirements/static-requirement.model';
import { dynamicRequirement } from '../models/concept-requirements/dyanmic-requriement.model';
import { driveCycle } from '../models/concept-requirements/drive-cycle-requirement.model';
import { wheelRadiusConfig } from '../models/concept-configurations/wheel-radius.model';
import { DriveCyclesState } from '../../state/lib/drive-cycles.state';
import { Snackbar } from '@ansys/awc-angular/popups';
import { NotificationService } from './notification.service';
import { DashboardTables } from '@ansys/andromeda/dashboard';

/**
 * @TODO Drive cycle logic
 */

@Injectable({ providedIn: 'root' })
export class ConceptService {
  public instanceId: string = '';
  private activeConcepts: ConceptPopulated[] = [];
  private _activeConcept!: ConceptPopulated;
  private loadingConcept!: string;

  public get activeConcept(): ConceptPopulated {
    return this._activeConcept;
  }

  public loading: WritableSignal<boolean> = signal(true);
  public loadingErrorMessage: WritableSignal<string | null> = signal(null);
  /**
   * Map of concept component/configuration types to their respective sections
   */
  public typeMap: Map<string, string> = new Map([
    ['MotorLossMap', PartType.MOTOR],
    ['MotorLossMapID', PartType.MOTOR],
    ['MotorTorqueCurves', PartType.MOTOR],
    ['MotorLabModel', PartType.MOTOR],
    ['MotorLabID', PartType.MOTOR],
    ['MotorTorqueCurveID', PartType.MOTOR],
    ['MotorCTCP', PartType.MOTOR],
    ['BatteryFixedVoltages', PartType.BATTERY],
    ['BatteryLookupTableID', PartType.BATTERY],
    ['BatteryLookupTable', PartType.BATTERY],
    ['BatteryLookupData', PartType.BATTERY],
    ['BatteryDiffertialVoltages', PartType.BATTERY],
    ['TransmissionLossCoefficients', PartType.TRANSMISSION],
    ['TransmissionLossMapID', PartType.TRANSMISSION],
    ['static_acceleration', PartType.STATIC_REQUIREMENT],
    ['dynamic_input', PartType.DYNAMIC_REQUIREMENT],
    ['InverterAnalytical', PartType.INVERTER],
    ['ClutchInput', PartType.CLUTCH],
    ['drive_cycle', PartType.DRIVE_CYCLE],
  ]);

  constructor(
    private conceptAPI: ConceptsService,
    private state: State,
    private treeService: TreeService,
    private authSession: AuthSessionService,
    private unitChoiceService: UnitChoicesService,
    private conceptUnits: ConceptUnitService,
    private architectureService: ArchitectureService,
    private configService: ConfigurationsService,
    private componentService: ComponentsService,
    private requirementService: RequirementsService,
    private actionService: ActionContributionsService,
    private driveCyclesService: DriveCyclesService,
    private projectAPI: ProjectApiService,
    private snackbar: SnackBarService,
    @Inject(NOTIFICATION_PROVIDER)
    private notifications: AWFNotificationProvider,
    private designApi: DesignApiService
  ) {
    this.state
      .get(ActiveConceptState)
      .subscribe((concept?: ConceptPopulated) => {
        this.instanceId = concept ? concept.design_instance_id : '';
      });
    this.state.get(AuthTokenState).subscribe((token) => {
      OpenAPI.TOKEN = token as string;
    });
  }

  /**
   * Will get concept object from relative ID and then proceed to get all relative data for populating the UI.
   * @param {string} instanceId - ID of the concept instance to open
   */
  public async openConcept(instanceId: string): Promise<void> {
    if (!OpenAPI.TOKEN) {
      OpenAPI.TOKEN = await this.authSession.getIdtoken();
    }
    this.loading.set(true);
    this.loadingErrorMessage.set(null);
    this.resetSectionState();
    this.actionService.execute(SetJobs, []);
    this.actionService.execute(SectionUpdateAction);
    this.getConcept(instanceId)
      .then(this.getConceptUnits.bind(this))
      .then(this.getConceptConfigurations.bind(this))
      .then(this.getConceptComponents.bind(this))
      .then(this.getConceptArchitecture.bind(this))
      .then(this.getConceptRequirements.bind(this))
      .then(async (concept) => {
        this._activeConcept = concept;
        this.state.set(ActiveConceptState, concept);
        this.actionService.execute(LoadAllJobs, concept);
        this.architectureService.getArchitecture(concept);

        this.loading.set(false);
        this.loadingErrorMessage.set(null);
      })
      .catch((error) => {
        this.loading.set(false);
        const message: string =
          error.body?.detail ?? error.message ?? 'Unable to load concept data.';
        this.loadingErrorMessage.set(message);
        console.error(error);
      });
  }

  public async clearConceptsAndFetch(): Promise<void> {
    this.activeConcepts = [];
    await this.openConcept(this.instanceId);
  }

  /**
   * Gets the study relative to the ID
   * @param {string} instanceId - ID of the study to get
   */
  public async getConcept(instanceId: string): Promise<ConceptPopulated> {
    const study = this.activeConcepts.find(
      (concept) => concept._id === instanceId
    );
    this.architectureService.resetArchitecture();
    if (study) return study;
    this.loadingConcept = instanceId;
    // concept not open in tab so this will get the concept from the server from id
    try {
      const concept = await this.$getConcept(instanceId);
      if (!concept?.id) throw new Error('Concept not found');
      if (instanceId !== this.loadingConcept)
        throw new Error('Concept out of sync');
      return concept;
    } catch (error: any) {
      throw new Error(error.message ?? 'Concept not found');
    }
  }

  private resetSectionState(): void {
    sectionState.architecture = false;
    sectionState.requirement = false;
  }

  private async getConceptUnits(
    concept: ConceptPopulated
  ): Promise<ConceptPopulated> {
    const units = await lastValueFrom(
      this.unitChoiceService.getInfoUnitChoicesInfoGet()
    );

    this.populateModelData(units);

    const unitChoices = await lastValueFrom(
      this.unitChoiceService.readUnitChoicesGet()
    );
    if (unitChoices.unit_type_to_unit_map) {
      this.conceptUnits.choices = unitChoices.unit_type_to_unit_map as any;
    }

    this.conceptUnits.unitInfo = units['unit_info'];
    this.conceptUnits.variableInfo = units['variable_info'];
    return concept;
  }

  private populateModelData(units: Record<string, any>): void {
    const modelsMap: Map<string, MapModelTypes> = new Map<
      string,
      MapModelTypes
    >([
      // Vehicle Configurations
      ['aeroConfig', aeroConfig],
      ['massConfig', massConfig],
      ['wheel', wheelRadiusConfig],
      ['ancillaryConfig', ancillaryConfig],
      ['decelerationConfig', decelerationConfig],

      // Component Configurations
      ['transmissionComponent', transmissionComponent],
      ['transmissionSelectTypes', transmissionSelectTypes],
      ['motorLossMap', motorLossMap],
      ['motorLabModel', motorLabModel],
      ['motorTorque', motorTorque],
      ['inverterSelectTypes', inverterSelectTypes],
      ['batteryComponent', batteryComponent],
      ['batterySelectTypes', batterySelectTypes],
      ['clutchComponent', clutchComponent],

      // Requirements
      ['staticRequirement', staticRequirement],
      ['dynamicRequirement', dynamicRequirement],
      ['driveCycle', driveCycle],
    ]);

    modelsMap.forEach((model, modelName) => {
      this.conceptUnits.setModelData(model, modelName, units);
    });
  }

  /**
   * Gets the study requirements relative to the study
   * @returns {Promise<ConceptPopulated>} - Returns the study with the requirements
   * @param concept
   */
  private async getConceptRequirements(
    concept: ConceptPopulated
  ): Promise<ConceptPopulated> {
    if (concept.design_instance_id !== this.loadingConcept) {
      throw new Error('Concept out of sync');
    }
    const requirements = await Promise.all(
      concept.requirements_ids.map(async (id) =>
        this.requirementService.get(id, concept.design_instance_id)
      )
    );
    requirements.forEach((requirement) => {
      this.treeService.addVehiclePartItem(
        this.typeMap.get(requirement.requirement_type as string) as string,
        requirement
      );
    });
    this.actionService.execute(UpdateInputs);
    return concept;
  }

  /**
   * Gets the drive cycles relative to the study
   *
   * @param concept
   * @param updateInputs
   */
  async getConceptDriveCycles(
    concept: ConceptPopulated,
    updateInputs: boolean = true
  ): Promise<string[][]> {
    if (concept.design_instance_id !== this.loadingConcept)
      throw new Error('Concept out of sync');

    const driveCycles: Record<string, string> = await lastValueFrom(
      this.driveCyclesService.listDriveCycleNamesDriveCyclesNamesGet(
        concept.design_instance_id
      )
    );

    const driveCyclesArray = Object.entries(driveCycles).map(([k, v]) => [
      k,
      v,
    ]);

    if (updateInputs) {
      this.actionService.execute(UpdateInputs);
    }

    return driveCyclesArray;
  }

  /**
   * Gets the study architecture relative to the study
   * @returns {Promise<ConceptPopulated>} - Returns the study with the architecture
   * @param concept
   */
  private async getConceptArchitecture(
    concept: ConceptPopulated
  ): Promise<ConceptPopulated> {
    if (concept.design_instance_id !== this.loadingConcept)
      throw new Error('Concept out of sync');

    const checkForComponents = (state: AWCTreeItem[]): boolean => {
      const _match = (part: AWCTreeItem, type: string): boolean => {
        return !!part.userData && part.userData['_parentID'] === type;
      };
      const transmission = state.find((part) => _match(part, 'transmission'));
      const motor = state.find((part) => _match(part, 'motor'));
      const battery = state.find((part) => _match(part, 'battery'));
      return !!(transmission && motor && battery);
    };
    const tab = this.state.value(ActiveTabState);
    const currentState = this.state.value(ComponentsState, tab?.id);
    if (concept.architecture_id?.length || checkForComponents(currentState)) {
      sectionState.architecture = true;
      sectionState.requirement = !!concept.architecture_id;
      this.actionService.execute(SectionUpdateAction);
    }
    return concept;
  }

  /**
   * Gets the study configurations relative to the study
   * @returns {Promise<ConceptPopulated>} - Returns the study with the configurations
   * @param concept
   */
  private async getConceptConfigurations(
    concept: ConceptPopulated
  ): Promise<ConceptPopulated> {
    if (concept.design_instance_id !== this.loadingConcept)
      throw new Error('Concept out of sync');
    if (!concept.configurations_ids.length) {
      return concept;
    } else {
      const configs = await Promise.all(
        concept.configurations_ids.map(async (id) =>
          this.configService.get(id, concept.design_instance_id)
        )
      );
      configs.forEach((config) => {
        this.treeService.addVehiclePartItem(
          config.config_type as string,
          config
        );
      });

      this.actionService.execute(UpdateInputs);
      return concept;
    }
  }

  /**
   * Gets the study components relative to the study
   * @returns {Promise<ConceptPopulated>} - Returns the study with the components
   * @param concept
   */
  private async getConceptComponents(
    concept: ConceptPopulated
  ): Promise<ConceptPopulated> {
    if (concept.design_instance_id !== this.loadingConcept)
      throw new Error('Concept out of sync');
    if (!concept.components_ids.length) {
      return concept;
    } else {
      const components = await Promise.all(
        concept.components_ids.map(async (id) =>
          this.componentService.get(id, concept.design_instance_id)
        )
      );
      components.forEach((component) => {
        this.treeService.addVehiclePartItem(
          this.typeMap.get(component.component_type as string) as string,
          component
        );
      });
      this.actionService.execute(UpdateInputs);
      return concept;
    }
  }

  public async getUnpopulatedConcept(id?: string): Promise<Concept> {
    const concept = (await lastValueFrom(
      this.conceptAPI.readConceptsDesignInstanceIdGet(
        id || this.instanceId,
        false
      )
    )) as Concept;
    return concept;
  }

  /**
   * Get Concept from Design Instance ID
   *
   * @param designInstanceId Design Instance ID
   */
  public async $getConcept(
    designInstanceId?: string
  ): Promise<ConceptPopulated> {
    try {
      const concept = (await lastValueFrom(
        this.conceptAPI.readConceptsDesignInstanceIdGet(
          designInstanceId || this.instanceId
        )
      )) as ConceptPopulated;
      this.activeConcepts.push(concept);
      return concept;
    } catch (error: any) {
      switch (error.status) {
        case 401:
        case 403:
          throw new Error('You do not have access to this concept.');
        case 404:
          throw new Error('No concept found in the database.');
        default:
          throw new Error('Unable to load concept data.');
      }
    }
  }

  public createConcept(design: Design): Promise<Concept> {
    return new Promise<Concept>((resolve) => {
      const instanceID: string | undefined = design?.designInstanceList
        ? design.designInstanceList[0].designInstanceId
        : undefined;

      this.conceptAPI
        .createConceptCheckConceptsPost(instanceID as string, {
          name: design.designTitle as string,
          project_id: design.projectId as string,
          user_id: design.userId as string,
          components_ids: [],
          configurations_ids: [],
          requirements_ids: [],
          jobs_ids: [],
          capabilities_ids: [],
          drive_cycles_ids: [],
          design_id: design.designId as string,
          design_instance_id: instanceID as string,
        })
        .subscribe((study: Concept) => {
          resolve(study);
        });
    });
  }

  public $createConcept(design: Design): Observable<Concept> {
    const instanceID: string | undefined = design?.designInstanceList
      ? design.designInstanceList[0].designInstanceId
      : undefined;
    return this.conceptAPI.createConceptCheckConceptsPost(
      instanceID as string,
      {
        name: design.designTitle as string,
        project_id: design.projectId as string,
        user_id: design.userId as string,
        components_ids: [],
        configurations_ids: [],
        requirements_ids: [],
        jobs_ids: [],
        capabilities_ids: [],
        drive_cycles_ids: [],
        design_id: design.designId as string,
        design_instance_id: instanceID as string,
      }
    );
  }

  public $createConceptFromDesignInstance(
    design: DesignInstance
  ): Observable<Concept> {
    return this.conceptAPI.createConceptCheckConceptsPost(
      design.designInstanceId as string,
      {
        name: design.designInstanceTitle,
        project_id: design.projectId as string,
        user_id: design.userId as string,
        components_ids: [],
        configurations_ids: [],
        requirements_ids: [],
        jobs_ids: [],
        capabilities_ids: [],
        drive_cycles_ids: [],
        design_id: design.designId as string,
        design_instance_id: design.designInstanceId as string,
      }
    );
  }

  public cloneConcept(
    context: Design | DesignInstance,
    newBranch?: boolean
  ): Observable<Concept | ConceptPopulated> {
    const instance: DesignInstance | undefined = !newBranch
      ? context
      : (context as Design).designInstanceList?.[0];
    const requestData: ConceptCloneInput = {
      old_design_instance_id: instance?.parentDesignInstanceId as string,
      new_design_instance_id: instance?.designInstanceId as string,
      copy_jobs: false,
    };

    // from root
    if (newBranch && !requestData.old_design_instance_id) {
      return this.$createConcept(context as Design);
    }
    return this.conceptAPI.copyConceptsCopyPost(
      false,
      requestData.old_design_instance_id,
      requestData
    );
  }

  public async updateProject(
    projectTitle: string,
    projectGoal: string | undefined = undefined,
    projectId: string | undefined = undefined
  ): Promise<boolean> {
    if (!projectId) {
      const concept = this.state.value(ActiveConceptState);
      if (!concept) {
        this.snackbar.error('No concept found');
        return false;
      }

      projectId = (concept as ConceptPopulated).project_id;
    }
    const payload: any = { projectId, projectTitle };
    if (projectGoal !== undefined) {
      payload.projectGoal = projectGoal;
    }

    const project = await lastValueFrom(
      this.projectAPI.projectUpdateHandler(payload)
    );

    if (!project) {
      this.snackbar.error('Failed to update project');
      return false;
    }

    this.notifications.addNotification({
      text: 'Project Updated',
      subText: `Project ${project.projectTitle} updated`,
      prefixIcon: {
        icon: Icons.FOLDER,
      },
    });
    this.snackbar.success('Project Updated');

    return true;
  }

  public async updateFile(fileTitle: string): Promise<boolean> {
    const concept = this.state.value(ActiveConceptState);
    if (!concept) {
      this.snackbar.error('No concept found');
      return false;
    }

    const fileId = (concept as ConceptPopulated).design_id;

    const file = await lastValueFrom(
      this.designApi.designUpdateHandler({
        designId: fileId,
        ...{ designTitle: fileTitle },
      })
    );

    if (!file) {
      this.snackbar.error('Failed to update file');
      return false;
    }

    // this.notifications.addNotification({
    //   text: 'File Updated',
    //   subText: `File ${design.designTitle} updated`,
    //   prefixIcon: {
    //     icon: Icons.FOLDER,
    //   },
    // });

    this.snackbar.success('File Updated');
    this.actionService.execute(TableRefreshDataAction, DashboardTables.FILES);

    return true;
  }
}
