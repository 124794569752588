import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionContributionsService } from '@ansys/andromeda/contributions';
import { OpenConceptAction } from './lib/open-concept.action';
import {
  AddJob,
  JobMetaData,
  SetJobs,
  UpdateJobProgress,
  UpdateJobStatus,
} from './lib/job-events.action';
import { UpdateInputs } from './lib/ui-events.action';
import { ComposeFullDatasetAction } from './lib/compose-full-dataset.action';
import { ComposeSingleDatasetAction } from './lib/compose-single-dataset.action';
import { DownloadFilesAction } from './lib/download-files.action';
import { BuildComponentCellAction } from './lib/build-component-cell.action';
import { ConvertUnitValuesAction } from './convert-unit-values/convert-unit-values.action';
import { ProcessStaticResultDataAction } from './process-static-result-data/process-static-result-data.action';
import { ProcessDynamicResultDataAction } from './process-dynamic-result-data/process-dynamic-result-data.action';
import { ProcessDriveCycleResultDataAction } from './process-drive-cycle-result-data/process-drive-cycle-result-data.action';
import { GetDriveCycleDataAction } from './get-drive-cycle-data/get-drive-cycle-data.action';
import { AddDriveCycleAxisAction } from './add-drive-cycle-axis/add-drive-cycle-axis.action';
import { SelectResultAction } from './select-result/select-result.action';
import { SolvedComponentTableAction } from './solved-component-table/solved-component-table.action';
import { ComposeInverterLossesAction } from './compose-invert-loss/compose-inverter-losses.action';
import { ProcessResultDataAction } from './process-result-data/process-result-data.action';
import { SetInverterDataActionAction } from './set-inverter-data-action/set-inverter-data-action.action';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ActionsModule {
  constructor(private actions: ActionContributionsService) {
    this.actions.contribute(OpenConceptAction);
    this.actions.contribute(AddJob);
    this.actions.contribute(SetJobs);
    this.actions.contribute(UpdateJobStatus);
    this.actions.contribute(JobMetaData);
    this.actions.contribute(UpdateInputs);
    this.actions.contribute(UpdateJobProgress);
    this.actions.contribute(ComposeFullDatasetAction);
    this.actions.contribute(ComposeSingleDatasetAction);
    this.actions.contribute(DownloadFilesAction);
    this.actions.contribute(BuildComponentCellAction);
    this.actions.contribute(ConvertUnitValuesAction);
    this.actions.contribute(ProcessStaticResultDataAction);
    this.actions.contribute(ProcessDynamicResultDataAction);
    this.actions.contribute(ProcessDriveCycleResultDataAction);
    this.actions.contribute(GetDriveCycleDataAction);
    this.actions.contribute(AddDriveCycleAxisAction);
    this.actions.contribute(SelectResultAction);
    this.actions.contribute(SolvedComponentTableAction);
    this.actions.contribute(ComposeInverterLossesAction);
    this.actions.contribute(ProcessResultDataAction);
    this.actions.contribute(SetInverterDataActionAction);
  }
}
