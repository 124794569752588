import { StateNode } from '@ansys/andromeda/store';
import { InverterLossesType } from '../../shared/enums/inverter-losses-type';

export type InverterOptions = {
  [key: string]: string | number | undefined;
  lossType: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dc_current?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  power_factor?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  phase_current_max?: number;
  frequency?: number;
};
export class InverterComponentOptionsState
  implements StateNode<InverterOptions>
{
  key: string = 'inverterComponentOptionsState';
  mutable: boolean = true;
  observeInitial: boolean = true;
  defaultValue: () => InverterOptions = () => {
    return {
      lossType: InverterLossesType.TOTAL,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dc_current: 50,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      phase_current_max: 400,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      power_factor: 1,
      frequency: 1000,
    };
  };
}
