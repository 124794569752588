import { ActionsEnum } from '../../shared/enums';
import { AWFAction } from '@ansys/andromeda/contributions';
import { MimeType } from '../../shared/enums/mime-type.enum';
import { SnackBarService } from '@ansys/andromeda/shared';
import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let JSZip: any;

type Input = [string, string | MultiFileContent, MimeType?];
export type MultiFileContent = { [file_name: string]: string };

/**
 * Given a file content or an array of file contents, Download the file in the case
 * of multiple files, .zip up and download them as one.
 *
 * @param folderName string
 * @param contents string | string[] a single file contents or list of file contests
 * @param mimeType string The MIME Type of the file or collection of files
 */
@Injectable({ providedIn: 'root' })
export class DownloadFilesAction implements AWFAction<Input, void> {
  public readonly type = ActionsEnum.DOWNLOAD_FILES;

  constructor(private snackbar: SnackBarService) {}

  execute: (context: Input) => Promise<void> = async (context: Input) => {
    const [fileFolderName, contents, mimeType = MimeType.CSV] = context;

    if (typeof contents !== 'string') {
      if (typeof JSZip !== 'undefined') {
        // Multiple files to be zipped up
        const zip = new JSZip();

        // Generate one zip file for all .csv files
        Object.keys(contents).forEach((componentName: string) => {
          const fileName =
            componentName.replace(/[^a-zA-Z0-9_-]/g, '') + '.csv';

          const bom = '\uFEFF';
          const fileContent = bom + contents[componentName];

          zip.file(fileName, fileContent);
        });

        zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          this.downloadFile(fileFolderName, content, MimeType.ZIP);
        });
      } else {
        this.snackbar.error('Error Downloading File');
      }
    } else {
      // Single file to download as is
      this.downloadFile(fileFolderName, contents, mimeType);
    }
  };

  private downloadFile(
    folderFileName: string,
    contents: string | Blob,
    mimeType: MimeType = MimeType.CSV
  ): void {
    const adjustedContents =
      typeof contents === 'string' ? '\uFEFF' + contents : contents;
    const blob = new window.Blob([adjustedContents], { type: mimeType });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = folderFileName;

    document.body.appendChild(link); // Required for FF
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}
