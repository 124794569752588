import { Injectable } from '@angular/core';
import { AndromedaMenuItem } from '@ansys/andromeda/contributions';
import { Icons } from '@ansys/awc-angular/icons';
import { DialogService } from '@ansys/andromeda/shared';
import { PopoutService } from '@ansys/awc-angular/popouts';
import { ProjectData } from '@ansys/cloud-angular-client';
import { ProjectRenameDialogComponent } from '../components/dialogs/project-rename-dialog/project-rename-dialog.component';

@Injectable({ providedIn: 'root' })
export class ProjectEditMenu implements AndromedaMenuItem {
  type = 'edit-project';
  priority = 200;
  id = 'edit-project';
  prefixIcon = {
    icon: Icons.EDIT,
  };
  text = 'Edit Project';
  context: ProjectData | undefined;

  constructor(
    private dialog: DialogService,
    private popoutService: PopoutService
  ) {}

  execute(): Promise<void> {
    console.log(this.context);
    this.dialog.open(ProjectRenameDialogComponent, {
      title: 'Edit Project',
      data: {
        type: 'project',
        title: this.context?.projectTitle,
        goal: this.context?.projectGoal,
        id: this.context?.projectId,
      },
    });

    return Promise.resolve();
  }
  render(): AndromedaMenuItem {
    return this;
  }
}
